import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { fetchVisits, fetchPatients, fetchAdmins } from "./actions/index";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import logo from "./img/logo.png"; // Adjust the import path as needed
import axios from "axios";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, FormControlLabel, Card, CardContent, Chip, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const styles = (theme) => ({
  // Define your styles here
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Administrator(props) {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState('');
  const [to, setTo] = useState('');
  const [all, setAll] = useState(false);
  const [toOptions, setToOptions] = useState([]); // Add options for autocomplete
  const [selectedOptions, setSelectedOptions] = useState([]); // State to manage selected options
  const [dialogOpen, setDialogOpen] = useState(false);

  const isButtonDisabled = !title || !subject || !content || selectedOptions.length === 0;
  
  useEffect(() => {
    // TODO: create a new action for fetching just non-unsubscribed patients
    props.fetchPatients();
    console.log("FETCHING PATIENTS");
  }, []);

  useEffect(() => {
    if (props.patients && props.patients.length > 0) {
      const emails = props.patients.map(patient => patient.intake.general.email);
      setToOptions(emails);
    }
  }, [props.patients]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleAddOption = (event, newValue) => {
    if (newValue && !selectedOptions.includes(newValue)) {
      setSelectedOptions([...selectedOptions, newValue]);
      setTo('');
    }
  };

  const handleDeleteOption = (optionToDelete) => () => {
    setSelectedOptions((options) => options.filter((option) => option !== optionToDelete));
  };

  const handleSubmit = async () => {
    try {
      await axios.post('/newsletter-send', {
        title,
        subject,
        selectedOptions,
        content
      });
      setSeverity('success');
      setMessage('Message sent successfully');
    } catch (error) {
      setSeverity('error');
      setMessage('Failed to send message');
    } finally {
      setOpen(true);
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <ThemeProvider>
        <Grid container spacing={3} style={{ marginTop: 50 }}>

          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h1" gutterBottom style={{ marginBottom: 40 }}>
                Send Newsletter
              </Typography>

              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                  onClose={handleClose}
                  severity={severity}
                  sx={{ width: "100%" }}
                >
                  {message}
                </Alert>
              </Snackbar>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  variant="outlined"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Subject"
                  variant="outlined"
                  fullWidth
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  freeSolo
                  options={toOptions}
                  value={to}
                  onChange={handleAddOption}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="To" fullWidth />}
                />
              </Grid>
              
              <Grid item xs={2} container alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={all}
                      onChange={(e) => {
                        setAll(e.target.checked);
                        if (e.target.checked) {
                          setSelectedOptions(toOptions);
                        } else {
                          setSelectedOptions([]);
                        }
                      }}
                      name="all"
                    />
                  }
                  label="All"
                />
              </Grid>
              
              <Grid item xs={12}>
                {all ? (<Chip key={"All Clients"} label={"All Clients"} />) : (selectedOptions.map((option) => (
                  <Chip
                    key={option}
                    label={option}
                    onDelete={handleDeleteOption(option)}
                    style={{ margin: '5px' }}
                  />
                )))}

              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Image URL"
                  variant="outlined"
                  fullWidth
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  theme="snow"
                  style={{ height: '400px' }}
                />
              </Grid>
              <Grid item xs={12}>
              <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleDialogOpen} 
                  // disabled={isButtonDisabled}
                  disabled
                  style={{marginTop: 40}}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5" component="h2" gutterBottom style={{ marginTop: 40 }}>
              Preview
            </Typography>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" component="h4" style={{ fontWeight: 'bold' }}>
                      Subject:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1" component="h4" align="right">
                      {subject}
                    </Typography>
                  </Grid>

                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1" component="h4" style={{ fontWeight: 'bold' }}>
                      To:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1" component="h4" align="right">
                      {selectedOptions.length > 0 ? selectedOptions.length + " client(s)" : 'No recipients'}
                    </Typography>
                  </Grid>

                </Grid>
                <hr style={{ opacity: 0.5 }} />

                <Box
                  sx={{
                    my: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ maxWidth: "150px", height: "auto" }}
                  />
                </Box>
                {image &&
                <Box
                sx={{
                  my: 4,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={image}
                  alt="Newsletter Image"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Box>
                }
                
                {!content && <div style={{ height: 100, backgroundColor: 'white', opacity: 0.5 }}><i>Your content here</i></div>}
                <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: content }} />
                <Typography variant="body1" component="div" dangerouslySetInnerHTML={{
                  __html: `
                  <hr style="opacity: 0.5;" />
                  
                ` }} />

                <Box
                  sx={{
                    my: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ maxWidth: "100px", height: "auto" }}
                  />
                </Box>
                <Typography variant="body1" component="div" dangerouslySetInnerHTML={{
                  __html: `
                  <p>
                    Schedule an appointment today by calling <a href="tel:+12898125518">(289) 812-5518</a><br />
                    <br />
                    <i style="opacity: 0.5;">Copyright © 2024 Appleby Wellness Clinic, All rights reserved.<br /> You are receiving this email because you visited the Appleby Wellness Clinic.</i>
                    <br /> <br />
                    Appleby Wellness Center
                    <br />
                    4265 Thomas Alton Blvd., Unit 208
                    <br />
                    Burlington ON 
                    <br />
                    L7M 0M9
                    <br /> 
                    <br /> 
                    Don't want to receive emails from us? <a href="https://cms.applebywellness.ca/unsubscribe">Unsubscribe</a>
                  </p>
                ` }} />

              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure you want to send this newsletter?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm that you want to send this newsletter.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    patients: state.patients,
    visits: state.visits,
    admins: state.admins,
    backendURL: state.url,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchVisits,
    fetchPatients,
    fetchAdmins,
  }),
  withRouter
)(Administrator);
