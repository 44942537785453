import React, { Component } from "react";
import {
  Button,
  Card,
  FormControl,
  OutlinedInput,
  InputLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import { login } from "../actions/postActions";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      institution: "",
      email: "",
      password: "",
      showPassword: false,
      errorMessage: "",
      successMessage: "",
      institutions: [],
      sentReset: false,
      user: this.props.user,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    // this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.reset = this.reset.bind(this);
    this.loginReq = this.loginReq.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  validateInput = () => {
    if (!this.state.email || this.state.email.length === 0)
      return "Please enter your email.";

    if (!this.state.password || this.state.password.length === 0)
      return "Please enter your password.";
    return "valid";
  };

  reset = () => {
    var promise = new Promise((resolve) => {
      var payload = {
        email: this.state.email,
      };
      console.log(payload);
      this.loginReq(resolve, payload);
    });
    return promise;
  };

  loginReq = (resolve, payload) => {
    axios
      .post(process.env.REACT_APP_DOMAIN + "/users/reset", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.successful === false) {
          console.log("login failed");
          this.setState({
            errorMessage:
              "There is an error. Please contact the Appleby Wellness Administrator",
          });

          // server's error message
          if (response.data.reason) {
            resolve(
              "There is an error. Please contact the Appleby Wellness Administrator"
            );
          }
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("");
          this.setState({
            successMessage:
              "Success! If your email is valid, you should receive an email with instructions to reset your password.",
          });
          this.setState({ email: "" });
          console.log(response);

          // this.setState({ isAuthenticated: true });
          // this.setState({ sentReset: true });
          // set user info to redux
          // console.log('login succeeded')
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        console.log(error);
        // Should send a response of 400 ***
        this.setState({
          errorMessage:
            "There is an error. Please contact the Appleby Wellness Administrator",
        });
        resolve(errorMsg);
      });
  };

  render() {
    const classes = {
      root: {},
      card: {},
      leftAlign: {
        textAlign: "left",
      },
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "90vh",
        }}
        className={classes.root}
      >
        {/* {this.state.sentReset && <Redirect to="/login" />} */}
        <Grid
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <Grid item>
            <div />
          </Grid>
          <Card
            style={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              padding: 30,
              width: 400,
            }}
          >
            <Grid style={{}} container direction={"column"} spacing={3}>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="h4">Forgot Password</Typography>
              </Grid>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="body2" style={{ color: "gray" }}>
                  Enter your email to receive your password reset link.
                </Typography>
              </Grid>
              {this.state.errorMessage.length > 0 && (
                <Grid item style={{ textAlign: "left" }}>
                  <Typography variant="body2" style={{ color: "red" }}>
                    {this.state.errorMessage}{" "}
                  </Typography>
                </Grid>
              )}
              {this.state.successMessage.length > 0 && (
                <Grid item style={{ textAlign: "left" }}>
                  <Typography variant="body2" style={{ color: "green" }}>
                    {this.state.successMessage}{" "}
                  </Typography>
                </Grid>
              )}

              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Email</InputLabel>
                  <OutlinedInput
                    type="text"
                    labelWidth={70}
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                  />
                </FormControl>
              </Grid>

              <Grid item container justify="center">
                <Button variant="outlined" color="primary" onClick={this.reset}>
                  Send
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Link id="Login" to={"/login"} style={{ textDecoration: "none" }}>
            <Typography
              variant="body2"
              style={{ color: "gray", marginTop: 25 }}
            >
              Back to Login
            </Typography>
          </Link>
          <Grid item>
            <div />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ForgotPassword;
