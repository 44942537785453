import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MedicalServices from "@material-ui/icons/LocalHospital";
import GroupIcon from "@material-ui/icons/Group";
import Grid from "@material-ui/core/Grid";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import axios from "axios";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import LogoutIcon from "@material-ui/icons/LockOutlined";
import CalendarToday from "@material-ui/icons/CalendarToday";
import PeopleOutlined from "@material-ui/icons/PeopleOutline";
import CreditCard from "@material-ui/icons/CreditCard";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import { Route } from "react-router-dom";
import Calendar from "../calendar";
import Patients from "../patients";
import Patient from "../patient";
import Queue from "../queue";
import Services from "../services";
import Practitioners from "../practitioners";
import Administrator from "../administrator";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import OrgLogo from "../img/logo.png";

import {
  fetchVisits,
  fetchAdmins,
  fetchPatients,
  getBackendURL,
  successfulAuth,
  successfulAuthOut,
} from "../actions/index";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
      border: "none",
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    border: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const preventDefault = (event) => event.preventDefault();

  const history = useHistory();

  function routeChangeCal() {
    history.push("/calendar");
  }

  function routeChangePat() {
    history.push("/patients");
  }

  function routeChangeUnp() {
    history.push("/queue");
  }

  function routeChangeServices() {
    history.push("/services");
  }

  function routeChangePractitioners() {
    history.push("/practitioners");
  }

  function routeChangeAdmin() {
    history.push("/administrator");
  }

  function logout() {
    // console.log("logout attempt")
    const token = localStorage.token;
    // console.log(token)
    axios
      .post(
        process.env.REACT_APP_DOMAIN + "/users/me/logout",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.token}`,
          },
        }
      )
      .then((response) => {
        // console.log("successful logout", response)
        //   this.props.successfulAuth("", "", "")
        //   setTimeout(history.push("/login"), 1000)
        props.successfulAuthOut();
        // pushToLogin()
        history.push("/login");
      })
      .catch((error) => {
        // console.log("error", error)
      });
  }

  const drawer = (
    <div>
      <div>
        <div style={{ width: "100%" }}>
          <Box
            display="flex"
            flexDirection="row"
            style={{ alignItems: "center" }}
            p={1}
            m={1}
          >
            <Box p={1} flexGrow={1}>
              {/* <Typography
                variant="h6"
                style={{ textAlign: "left", padding: 8, paddingLeft: 0 }}
              >
                
                Organica
              </Typography> */}
              <img src={OrgLogo} style={{ width: 80 }} />
            </Box>
            <Box p={1}>
              <div
                aria-label="open drawer"
                // onClick={handleDrawerToggle}
                style={{ color: "black" }}
              >
                <MenuOpenIcon />
              </div>
            </Box>
          </Box>
        </div>
      </div>

      <List style={{ paddingLeft: 8 }}>
        <ListItem button onClick={routeChangeCal}>
          <ListItemIcon style={{ color: "black" }}>
            <CalendarToday />
          </ListItemIcon>
          <ListItemText>Calendar</ListItemText>
        </ListItem>
        {(props.admin.permissions === "Administrator" ||
          props.admin.permissions === "Developer") && (
          <>
            <ListItem button onClick={routeChangePat}>
              <ListItemIcon style={{ color: "black" }}>
                <PeopleOutlined />
              </ListItemIcon>
              <ListItemText>Patients</ListItemText>
            </ListItem>
            <ListItem button onClick={routeChangeUnp}>
              <ListItemIcon style={{ color: "black" }}>
                <CreditCard />
              </ListItemIcon>
              <ListItemText>Unpaid</ListItemText>
            </ListItem>
            <ListItem button onClick={routeChangeServices}>
              <ListItemIcon style={{ color: "black" }}>
                <MedicalServices />
              </ListItemIcon>
              <ListItemText>Services</ListItemText>
            </ListItem>
            <ListItem button onClick={routeChangePractitioners}>
              <ListItemIcon style={{ color: "black" }}>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText>Practitioners</ListItemText>
            </ListItem>
            <ListItem button onClick={routeChangeAdmin}>
              <ListItemIcon style={{ color: "black" }}>
                <MailIcon />
              </ListItemIcon>
              <ListItemText>Newsletter</ListItemText>
            </ListItem>
          </>
        )}
      </List>
      <div style={{ paddingLeft: 25, position: "absolute", bottom: 15 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="button">{props.admin.name}</Typography> <br />
            <Typography variant="caption">{props.admin.permissions}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="logout"
              // onClick={handleDrawerToggle}
              style={{ color: "black", bottom: 4 }}
              onClick={logout}
            >
              <LogoutIcon />
            </IconButton>
          </Grid>
        </Grid>
        <div></div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />

      <nav
        className={classes.drawer}
        aria-label="mailbox folders"
        style={{ border: "none" }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            elevation={0}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
            style={{ border: "none" }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Route exact path="/calendar" component={Calendar} />
        <Route exact path="/patients" component={Patients} />
        <Route path="/patient/:id" component={Patient} />
        <Route exact path="/queue" component={Queue} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/practitioners" component={Practitioners} />
        <Route exact path="/administrator" component={Administrator} />
      </main>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
  };
}

export default compose(
  withStyles(),
  connect(mapStateToProps, {
    successfulAuthOut,
  })
)(withRouter(ResponsiveDrawer));
