import React, { Component } from "react";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
// import { login } from "../actions/postActions";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Redirect } from "react-router-dom";

import qs from "qs";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      institution: "",
      email: "",
      password: "",
      passwordAgain: "",
      showPassword: false,
      showPasswordAgain: false,
      errorMessage: "",
      successMessage: "",
      institutions: [],
      isAuthenticated: false,
      user: this.props.user,
      resetSuccess: false,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.reset = this.reset.bind(this);
    this.resetReq = this.resetReq.bind(this);
  }

  componentWillReceiveProps(nextState) {
    // load in all calendar events on init
    // console.log('next state', nextState.admin)
    this.setState({
      user: nextState.user,
    });
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowPasswordAgain = () => {
    this.setState((state) => ({ showPasswordAgain: !state.showPasswordAgain }));
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  validateInput = () => {
    if (!this.state.email || this.state.email.length === 0)
      return "Please enter your email address";
    if (!this.state.password || this.state.password.length === 0)
      return "Please enter a password";
    return "valid";
  };

  reset = () => {
    // check that passwords match or give error.
    if (this.state.password !== this.state.passwordAgain) {
      this.setState({
        errorMessage: "Passwords do not match!",
      });
    } else {
      var promise = new Promise((resolve) => {
        // get token and ID from URL
        var payload = {
          token: qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
          }).token,
          userId: qs.parse(this.props.location.search, {
            ignoreQueryPrefix: true,
          }).id,
          password: this.state.password,
        };
        console.log(payload);
        this.resetReq(resolve, payload);
      });
      return promise;
    }
  };

  // login = () => {
  //   var promise = new Promise((resolve) => {
  //     var payload = {
  //       email: this.state.email,
  //       password: this.state.password,
  //     };
  //     console.log(payload);
  //     this.loginReq(resolve, payload);
  //   });
  //   return promise;
  // };

  resetReq = (resolve, payload) => {
    axios
      .post(process.env.REACT_APP_DOMAIN + "/users/resetpassword", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.successful === false) {
          console.log("reset failed");
          this.setState({
            errorMessage: "Password reset failed!",
          });

          // server's error message
          if (response.data.reason) {
            resolve("Password reset failed!");
          }
          var errorMsg = response.data.reason;
          resolve(errorMsg);
        } else {
          resolve("");
          console.log(response);
          resolve("Success! You will now be redirected to login..");

          // success! user needs to login again
          this.setState({
            successMessage:
              "Success! If your email is valid, you should receive an email with instructions to reset your password.",
          });
          setTimeout(() => {
            this.setState({
              resetSuccess: true,
            });
          }, "2000");

          // this.setState({ isAuthenticated: true });
          // set user info to redux
          // console.log('login succeeded')
        }
      })
      .catch((error) => {
        var errorMsg = "Missing data";
        console.log(error);
        // Should send a response of 400 ***
        this.setState({
          errorMessage: "Password reset failed!",
        });
        resolve(errorMsg);
      });
  };

  render() {
    const classes = {
      root: {},
      card: {},
      leftAlign: {
        textAlign: "left",
      },
    };
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "90vh",
        }}
        className={classes.root}
      >
        <Grid
          container
          alignItems="center"
          direction="column"
          justify="space-between"
        >
          <Grid item>
            <div />
          </Grid>
          <Card
            style={{
              boxShadow: "none",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              padding: 30,
              minWidth: 400,
            }}
          >
            <Grid style={{}} container direction={"column"} spacing={3}>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="h4">Reset Password</Typography>
              </Grid>
              <Grid item style={{ textAlign: "left" }}>
                <Typography variant="body2" style={{ color: "gray" }}>
                  Please enter your new password twice.
                </Typography>
              </Grid>
              {this.state.errorMessage.length > 0 && (
                <Grid item style={{ textAlign: "left" }}>
                  <Typography variant="body2" style={{ color: "red" }}>
                    {this.state.errorMessage}{" "}
                  </Typography>
                </Grid>
              )}
              {this.state.successMessage.length > 0 && (
                <Grid item style={{ textAlign: "left" }}>
                  <Typography variant="body2" style={{ color: "green" }}>
                    {this.state.successMessage}{" "}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>New Password</InputLabel>
                  <OutlinedInput
                    type={this.state.showPassword ? "text" : "password"}
                    labelWidth={this.props.language == "en" ? 120 : 180}
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Verify New Password</InputLabel>
                  <OutlinedInput
                    type={this.state.showPasswordAgain ? "text" : "password"}
                    labelWidth={this.props.language == "en" ? 170 : 230}
                    value={this.state.passwordAgain}
                    onChange={this.handleChange("passwordAgain")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPasswordAgain}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.showPasswordAgain ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item container justify="center">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.reset}
                  disabled={
                    (this.state.password.length > 5 &&
                      this.state.passwordAgain.length) > 5
                      ? false
                      : true
                  }
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Card>
          <Link
            id="ResetPassword"
            to={"/forgot"}
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="body2"
              style={{ color: "gray", marginTop: 25 }}
            >
              Request Reset Link
            </Typography>
          </Link>
          <Grid item>
            <div />
          </Grid>
        </Grid>
        {this.state.resetSuccess && <Redirect to="/login" />}
      </div>
    );
  }
}

export default ResetPassword;
