import React, { Component } from "react";
import "./App.css";
import Login from "./login";
import SignUp from "./signup";
import Services from "./services";

import PatientSignIn from "./patientSignIn";
import IntakeForm from "./forms/intakeForm";
import TreatmentNotes from "./forms/treatmentNotes";
import VisitLog from "./visitLog";
import Administrator from "./administrator";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Calendar from "./calendar";
import ResponsiveDrawer from "./components/navbar";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {
  fetchVisits,
  fetchAdmins,
  fetchPatients,
  getBackendURL,
  successfulAuth,
} from "./actions/index";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Unsubscribe from "./unsubscribe";

import ResetPassword from "./pages/reset_password";
import ForgotPassword from "./pages/forgot_password";
// function App() {
//   return (
//     <div className="App">
//       <Main />
//     </div>
//   );
// }

class App extends Component {
  constructor(props) {
    super(props);
    this.classes = props;
    this.state = {
      isAuthenticated: false,
      admin: this.props.admin,
      loading: true,
    };
    // console.log("backend connection: ", process.env.REACT_APP_DOMAIN)
  }
  // state = {isAuthenticated: true};

  checkLoginStatus = () => {
    const token = localStorage.token;
    axios
      .get(process.env.REACT_APP_DOMAIN + "/users/me", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        // console.log("successful login", response)
        this.props.successfulAuth(
          response.data.email,
          response.data._id,
          response.data.name,
          response.data.permissions
        );
        this.setState({
          loading: false,
        });
        // this.props.fetchPatients().then(() => {

        // })
      })
      .catch((error) => {
        // console.log("error", error)
        this.setState({
          loading: false,
        });
      });
  };

  PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          this.props.admin.loggedIn && !this.state.loading ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  // Block routes for practioners/users. In tandem with backend permissions.
  PrivateAdminRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          this.props.admin.loggedIn && (this.props.admin.permissions === "Administrator" || this.props.admin.permissions === "Developer") && !this.state.loading ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/calendar", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };

  componentDidMount() {
    this.checkLoginStatus();
    // this.props.fetchVisits(
    //   moment().subtract(1, "days").format("YYYY-MM-DD"),
    //   moment().add(1, "days").format("YYYY-MM-DD")
    // );
    this.props.fetchAdmins();
    console.log("admin", this.props.admin);
  }

  componentWillReceiveProps(nextState) {
    // load in all calendar events on init
    // console.log('next state', nextState.admin)
    this.setState({
      admin: nextState.admin,
    });
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <main>
          {this.state.loading ? (
            <div>
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  width: 500,
                  height: 200,
                  marginTop: -100,
                  marginLeft: -250,
                  textAlign: "center",
                }}
              >
                <Typography component="h1" style={{ fontSize: 25 }}>
                  Welcome to Appleby Wellness
                </Typography>
                <Typography component="p" style={{ fontWeight: "lighter" }}>
                  Loading in your information.
                </Typography>
                <CircularProgress style={{ margin: "3rem" }} />
              </div>
            </div>
          ) : (
            <Router>
              <Switch>
                {/* <Redirect exact from="/" to="calendar" /> */}
                {/* {!this.state.isAuthenticated &&
            <Redirect to='/login' />
          } */}

                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/sign_in" component={PatientSignIn} />
                <Route exact path="/intake/:id" component={IntakeForm} />
                <Route exact path="/unsubscribe" component={Unsubscribe} />
                <Route exact path="/reset" component={ResetPassword} />
                <Route exact path="/forgot" component={ForgotPassword} />
                <this.PrivateRoute
                  exact
                  path="/calendar"
                  component={ResponsiveDrawer}
                />
                <this.PrivateRoute
                  exact
                  path="/patients"
                  component={ResponsiveDrawer}
                />
                <this.PrivateRoute
                  path="/patient/:id"
                  component={ResponsiveDrawer}
                />
                <this.PrivateRoute
                  path="/admin/intake/:id/:admin/:index?"
                  component={IntakeForm}
                />
                <this.PrivateRoute
                  path="/admin/treatmentnotes/:id/:visit"
                  component={TreatmentNotes}
                />
                <this.PrivateAdminRoute
                  path="/services"
                  component={ResponsiveDrawer}
                />
                <this.PrivateAdminRoute
                  path="/practitioners"
                  component={ResponsiveDrawer}
                />
                <this.PrivateAdminRoute
                  exact
                  path="/administrator"
                  component={ResponsiveDrawer}
                />
                <this.PrivateRoute path="/visitlog/:id" component={VisitLog} />
                <this.PrivateAdminRoute
                  exact
                  path="/queue"
                  component={ResponsiveDrawer}
                />
                <Redirect to="/calendar" />
              </Switch>
            </Router>
          )}
        </main>
      </MuiPickersUtilsProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    admin: state.admin,
  };
}

export default connect(mapStateToProps, {
  // fetchVisits,
  fetchAdmins,
  fetchPatients,
  getBackendURL,
  successfulAuth,
})(App);
